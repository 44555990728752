const modalImg = () => {
    const modalImgContent = document.getElementById('modal-image');
    const modalImg = document.getElementById('modal-img');
    const items = document.querySelectorAll('.items img');
    const closeButton = document.querySelector('.close-img');

    // Функция для открытия первого модального окна
    function openModalImg() {
        modalImg.style.display = 'block';
        modalImgContent.src = this.src;
        modalImgOpened = true;
        setTimeout(() => {
            modalOpened = false;
        }, 60000); // Закрываем первое модальное окно через 60 секунд
    }

    // Закрытие модального окна по крестику
    closeButton.addEventListener('click', function() {
        modalImg.style.display = 'none';
        modalImgOpened = false;
    });

    // Закрытие модального окна по клику вне его области
    window.addEventListener('click', function(event) {
        if (event.target.classList.contains('modal-img-item')) {
            modalImg.style.display = 'none';
            modalImgOpened = false;
        }
    });

    // Добавляем обработчики событий для открытия первого модального окна при клике на изображения
    items.forEach(item => {
        item.addEventListener('click', openModalImg);
    });
};

export default modalImg;