const dataSending = () => {
    const mailPath = './assets/mail.php';

    document.querySelectorAll('.uniForm').forEach((form) => {
        form.addEventListener('submit', function (e) {
            e.preventDefault();

            if (!isFormValid(this)) {
                showMessage('Пожалуйста, заполните все поля формы.', 'error');
                return;
            }

            let th = this,
                params = new FormData(this),
                request = new XMLHttpRequest();

            request.open('POST', mailPath, true);
            request.send(params);

            request.onreadystatechange = function () {
                if (this.readyState == 4) {
                    if (this.status == 200) {
                        showMessage('Спасибо! Скоро мы с вами свяжемся.', 'success');
                        setTimeout(function () {
                            th.reset();
                        }, 1000);
                    } else {
                        showMessage('Что-то пошло не так. Пожалуйста, попробуйте снова.', 'error');
                    }
                }
            };
        });
    });
};

const isFormValid = (form) => {
    // Проверка заполненности всех полей формы
    let isValid = true;
    form.querySelectorAll('input, textarea').forEach((field) => {
        if (!field.value.trim()) {
            isValid = false;
        }
    });
    return isValid;
};

// Остальной код остается без изменений
const showMessage = (text, type) => {
    const messageContainer = document.getElementById('message-container');

    const messageElement = document.createElement('div');
    messageElement.className = `message ${type}`;
    messageElement.textContent = text;

    messageContainer.innerHTML = '';
    messageContainer.appendChild(messageElement);

    setTimeout(() => {
        messageContainer.innerHTML = '';
    }, 5000);
};

export default dataSending;
