const sliders = () => {
  let currentSlide = 0; // Индекс текущего слайда

  // Функция для отображения нужного слайда
  function showSlide(index) {
    const slides = document.querySelectorAll('.slide');
    const dots = document.querySelectorAll('.dot');
  
    // Скрываем все слайды и точки
    slides.forEach(slide => slide.style.display = 'none');
    dots.forEach(dot => dot.classList.remove('active'));
  
    // Показываем нужный слайд и выделяем соответствующую точку
    slides[index].style.display = 'block';
    dots[index].classList.add('active');
  }
  
  // Функция для переключения на предыдущий слайд
  function prevSlide() {
    currentSlide = (currentSlide - 1 + slides.length) % slides.length;
    showSlide(currentSlide);
  }
  
  // Функция для переключения на следующий слайд
  function nextSlide() {
    currentSlide = (currentSlide + 1) % slides.length;
    showSlide(currentSlide);
  }
  
  // Добавление слушателей событий для кнопок "prev" и "next"
  document.querySelector('.prev-btn').addEventListener('click', prevSlide);
  document.querySelector('.next-btn').addEventListener('click', nextSlide);
  
  // Инициализация точек (dots)
  const dotsContainer = document.querySelector('.dots-container');
  const slides = document.querySelectorAll('.slide');
  
  slides.forEach((slide, index) => {
    // Создаем точку и добавляем обработчик событий
    const dot = document.createElement('span');
    dot.classList.add('dot');
    dot.addEventListener('click', () => {
      currentSlide = index;
      showSlide(currentSlide);
    });
  
    // Добавляем точку в контейнер
    dotsContainer.appendChild(dot);
  });
  
  // Показываем первый слайд
  showSlide(currentSlide);
  
};

export default sliders;