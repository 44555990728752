import sliders from './modules/sliders';
import dataSending from'./modules/dataSending';
import form from './modules/form';
import mask from './modules/mask';
import modal from './modules/modal';
import modalPortfolio from './modules/modalPortfolio';
import show from './modules/show';
import modalImg from './modules/modalImg';
import map from './modules/map';

window.addEventListener('DOMContentLoaded', () => {
    'use strict';

    sliders();
    dataSending();
    form();
    mask();
    modal();
    modalPortfolio();
    show();
    modalImg();
    map();
});