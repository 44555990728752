const modal = () => {
    setTimeout(function () {
        document.querySelector('.modal').style.display = 'flex';
    }, 60000);

    document.querySelector('.close').addEventListener('click', function () {
        document.querySelector('.modal').style.display = 'none';
    });

    window.addEventListener('click', function (event) {
        var modal = document.querySelector('.modal');
        if (event.target === modal) {
            modal.style.display = 'none';
        }
    });
};

export default modal;