const modalPortfolio = () => {
    const modalPortfolioContent = document.getElementById('modal-portfolio-image');
    const modalPortfolio = document.getElementById('modal-portfolio');
    const portfolioImages = document.querySelectorAll('.portfolio-content-images img');
    const closePortfolioButton = document.querySelector('.close-portfolio');

    let modalPortfolioOpened = false; // Переменная для отслеживания состояния модального окна портфолио

    // Функция для открытия модального окна портфолио
    function openModalPortfolio() {
        modalPortfolio.style.display = 'block';
        modalPortfolioContent.src = this.src;
        modalPortfolioOpened = true;
    }

    // Закрытие модального окна портфолио по крестику
    closePortfolioButton.addEventListener('click', function() {
        modalPortfolio.style.display = 'none';
        modalPortfolioOpened = false;
    });

    // Закрытие модального окна портфолио по клику вне его области
    window.addEventListener('click', function(event) {
        if (event.target.classList.contains('modal-portfolio-item')) {
            modalPortfolio.style.display = 'none';
            modalPortfolioOpened = false;
        }
    });

    // Добавляем обработчики событий для открытия модального окна портфолио при клике на изображения портфолио
    portfolioImages.forEach(image => {
        image.addEventListener('click', openModalPortfolio);
    });
};

export default modalPortfolio;