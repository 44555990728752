const form = () => {
    var form = document.querySelector('.uniForm');
    form.addEventListener('submit', function(event) {
        var inputs = form.querySelectorAll('input:not([type="submit"]), textarea');

        for (var i = 0; i < inputs.length; i++) {
            if (!inputs[i].value.trim()) {
                event.preventDefault();
                inputs[i].style.border = '1px solid rgb(218, 64, 64)';
                inputs[i].style.backgroundColor = 'rgb(255, 232, 232)';
            } else {
                inputs[i].style.border = '';
                inputs[i].style.backgroundColor = '';
            }
        }
    });
};

export default form;