const show = () => {
    document.getElementById("showMoreBtn").addEventListener("click", function() {
        var hiddenParagraphs = document.querySelectorAll(".hidden");
        hiddenParagraphs.forEach(function(paragraph) {
            paragraph.classList.remove("hidden");
        });
        this.remove();
    });
};

export default show;